import { sprintf } from "../sprintf/sprintf.esm.js"
import { Events } from "../events/events.ts"
import { cookiesClear, cookiesSet, 
	CookiesClearEvent, CookiesSetEvent } from "./event.ts"
import { setCookie, getCookie, removeCookie } from "./api.ts"
import { CookieAttributes } from "./types.ts"

export class CookiesErr extends Error {
	name = ""

	// deno-lint-ignore no-explicit-any
	constructor(name: string, ...params: any) {
		super(...params)
		this.name = name
	}

	// error can be used for logging, 
	// i.e. console.error(...cookiesError.error())
	// deno-lint-ignore no-explicit-any
	error(): any[] {
		return [this.string()]
	}

	string(): string {
		if (this.name) {
			return sprintf("%s %s", sprintf("cookie %s", this.name), this.message)
		}
		return this.message
	}
}

// Cookie helper class
// https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie
export class Cookies {
	#initialised = false

	// deno-lint-ignore no-explicit-any
	static isErr(err: any): boolean {
		if (err instanceof CookiesErr) {
			return true
		}
		return false
	}

	static val(name: string): string | CookiesErr {
		const val = getCookie(name)
		if (val == undefined) {
			return new CookiesErr(name, "undefined")
		}
		return val
	}

	static setDefaultAttrs(attrs?: CookieAttributes): CookieAttributes {
		if (!attrs) {
			attrs = {}
		}
		// "When deleting a cookie you must pass the exact same path and domain 
		// attributes that were used to set the cookie"
		// https://github.com/carhartl/typescript-cookie#basic-usage
		attrs.path = "/",
		attrs.domain = ""
		return attrs
	}

	static set(name: string, value: string, attrs?: CookieAttributes) {
		attrs = Cookies.setDefaultAttrs(attrs)
		setCookie(name, value, attrs)
	}

	// clear requires matching name, path and domain attrs
	static clear(name: string) {
		const attrs = Cookies.setDefaultAttrs({})
		removeCookie(name, attrs)
	}

	init() {
		if (this.#initialised) {
			console.error("already initialised")
			return
		}

		Events.addListener(Cookies.name, cookiesSet, (e: Event) => {
			const event = e as CookiesSetEvent
			if (!event.detail) {
				console.error("invalid detail", event)
				return
			}
			if (!event.detail.value) {
				console.error("invalid detail value", event)
				return
			}
			for (const item of event.detail.value) {
				if (!item.name) {
					console.error("invalid name", event)
					return
				}
				if (!item.value) {
					console.error("invalid value", event)
					return
				}
				Cookies.set(item.name, item.value, item.attrs)
			}
		})

		Events.addListener(Cookies.name, cookiesClear, (e: Event) => {
			const event = e as CookiesClearEvent
			if (!event.detail) {
				console.error("invalid detail", event)
				return
			}
			if (!event.detail.name) {
				console.error("invalid cookie name", event)
				return
			}
			Cookies.clear(event.detail.name)
		})

		this.#initialised = true
	}
}
