import { Alpine } from "../../deps.ts"
import { Events } from "../events/events.ts"
import { alpineInit } from "../events/alpine.ts"
import { sprintf } from "../sprintf/sprintf.esm.js"
// Import as _values since this is not currently used in here,
// but keep the import for future reference
import { values as _values } from "../../types/htmx.d.ts"

// ParamsMap maps lowercase name to value for URLSearchParams
export type ParamsMap = Map<string, string>

export class Utils {
	// "TypeScript public/private keywords only apply to the way TypeScript 
	// checks your code - they don't have any effect on the JavaScript output...
	// Private methods using # are now implemented"
	// https://stackoverflow.com/a/16919899/639133
	#initialised = false

	#params: ParamsMap

	constructor() {
		const sp = new URLSearchParams(document.location.search)
		this.#params = new Map<string, string>
		sp.forEach((value, key) => {
			this.#params.set(key.toLowerCase(), value)
		})
	}

	// query param with the specified name is returned.
	// TODO Sanitize user input?
	// "be aware that you are introducing security considerations, 
	// when dealing with user input such as query strings... 
	// which could introduce a Cross-Site Scripting (XSS) vulnerability"
	// https://htmx.org/attributes/hx-vars/ 
	// Also consider this, above page links to the following
	// "it's crucial that you turn off HTTP TRACE support on all web servers... 
	// session hijack attack"
	// https://owasp.org/www-community/attacks/xss/
	query(name: string) {
		const key = name.toLowerCase()
		return this.#params.get(key)
	}

	init() {
		if (this.#initialised) {
			console.error("already initialised")
			return
		}

		Events.addListener(Utils.name, alpineInit, () => {
			// deno-lint-ignore no-explicit-any
			Alpine.directive("query-trigger", (_el: Node, o: any) => {
				// Query param triggers the htmx request
				const param = o.expression
				const event = this.query(param)
				if (event) {
					const target = document.body.querySelector(
						sprintf('[hx-trigger="%s"]', event))
					if (target) {
						Events.dispatchOnElement(Utils.name, target, event)
					}
				}
			})
		})

		this.#initialised = true
	}
}
