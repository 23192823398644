import { Events } from "../events/events.ts"
import { htmxBeforeSwap } from "./htmx.ts"
import { HtmxResponseEvent } from "../../types/htmx-custom.d.ts"

// HtmxUtil
export class HtmxUtils {
	#initialised = false

	init() {
		if (this.#initialised) {
			console.error("already initialised")
			return
		}

		// The response-targets extension is used for error placement
		// because hx-swap-oob is not processed for errors
		// https://github.com/bigskysoftware/htmx/issues/2564
		Events.addListener(HtmxUtils.name, htmxBeforeSwap, (e: Event) => {
			const event = (e as HtmxResponseEvent)
			if (!event.detail) {
				console.error("invalid detail", event)
				return
			}
			if (!event.detail.elt) {
				console.error("invalid detail.elt", event.detail)
				return
			}
			if (!event.detail.xhr) {
				console.error("invalid detail.xhr", event.detail)
				return
			}
			if (event.detail.xhr.status >= 400) {
				// Display errors by default
				// https://htmx.org/docs/#modifying_swapping_behavior_with_events
				event.detail.shouldSwap = true
				// Set isError to false to avoid error logging in console
				// event.detail.isError = false
			}
		})

		this.#initialised = true
	}
}
