import { RuleMap, RuleName, ValidationRule } from "./libs/validation/validation.ts"

// function getCustomInput(term: string): HTMLInputElement | null {
// 	// https://developer.mozilla.org/en-US/docs/Web/API/Document/querySelector
// 	const selector = sprintf("#%s-%s", "config", term)
// 	const element = document.querySelector(selector)
// 	if (element == null) {
// 		return null
// 	}
// 	return element as HTMLInputElement
// }

export function getCustomRules(): RuleMap {
	const customRules: RuleMap = new Map<RuleName, ValidationRule>()

	// TODO Remove this, just generate the SKU on the backend?

	// const ruleSkuGen = "sku-gen"
	// // deno-lint-ignore no-explicit-any
	// customRules.set(ruleSkuGen, (value: any): ValidationResult => {
	// 	// TODO Make this generic for all fields with id starting with "custom-"
	// 	const elSkuGen = document.querySelector("#sku-gen") as HTMLInputElement
	// 	const elBinomial = getCustomInput("binomial")
	// 	const elCultivar = getCustomInput("cultivar")
	// 	const binomial = elBinomial?.value
	// 	const cultivar = elCultivar?.value

	// 	if (binomial && cultivar) {
	// 		// Set hx-params to filter form fields
	// 		elSkuGen.setAttribute("hx-params", "binomial, cultivar")
	
	// 		// Trigger hx-post.
	// 		// TODO Avoid using standard events that may be dispatched elsewhere
	// 		Events.dispatchOnElement(ruleSkuGen, elSkuGen, click)
	// 	}

	// 	return {
	// 		rule: ruleSkuGen,
	// 		// Always valid, this rule is used to trigger hx-post,
	// 		// after checking any other validation that may be required
	// 		valid: true, 
	// 		msg: "",
	// 		value: value
	// 	}
	// })

	return customRules
}
