export const cookiesClear = "cookies:clear"
export interface CookiesClearEvent extends Event {
	detail: {
		name: string
	}
}

export const cookiesSet = "cookies:set"
export interface CookiesSetEvent extends Event {
	detail: {
		value: [{
			name: string
			value: string
			attrs?: {
				expires: number // days
			}
		}]
	}
}
