/**
 * Toggle visibility of elements (headers, links, etc) on page load.
 * For example, is the user logged-in, what is the user role, etc.
 *
 * Navbar end icons, and navbar action links, are managed by this class.
 * This does not manage page actions, i.e. links specific to page resources.
 * Page actions are specified in the Hypermedia API responses,
 * and subject to change in accordance with HATEOAS and REST principles.
 *
 * Obviously this can't prevent users from opening links,
 * the server will auth actions with the session cookie
 */

import { Cookies } from "./libs/cookies/cookies.ts";

// All elements may not be rendered on all the pages of the static site.
// The code below must ignore missing elements

// Headers
const headerLogin = "app-header-login"
const headerLoginVerifyEmail = "app-header-login-verify-email"
const headerLoginSwitchProfile = "app-header-login-switch-profile"

// Links
const linkAdmin = "app-link-admin"
// Cart link is toggled by the class
const linkLogin = "app-link-login"
const linkLogout = "app-link-logout"
const linkOrders = "app-link-orders"
const linkProfile = "app-link-profile"

// Do not change the display property on the element style
// https://developer.mozilla.org/en-US/docs/Web/CSS/display
// Instead, toggle the Bulma Response Helpers
// https://bulma.io/documentation/helpers/visibility-helpers/#hide
export const isHidden = "is-hidden"

export class Toggle {
	// Headers
	#headerLogin?: HTMLCollectionOf<Element>
	#headerLoginSwitchProfile?: HTMLCollectionOf<Element>
	#headerLoginVerifyEmail?: HTMLCollectionOf<Element>

	// Links
	#linkProfile?: HTMLCollectionOf<Element>
	#linkAdmin?: HTMLCollectionOf<Element>
	#linkLogin?: HTMLCollectionOf<Element>
	#linkLogout?: HTMLCollectionOf<Element>
	#linkOrders?: HTMLCollectionOf<Element>

	constructor() {
		// Headers
		let elems = document.getElementsByClassName(headerLogin)
		if (elems.length > 0) {
			this.#headerLogin = elems
		}
		elems = document.getElementsByClassName(headerLoginSwitchProfile)
		if (elems.length > 0) {
			this.#headerLoginSwitchProfile = elems
		}
		elems = document.getElementsByClassName(headerLoginVerifyEmail)
		if (elems.length > 0) {
			this.#headerLoginVerifyEmail = elems
		}

		// Links
		elems = document.getElementsByClassName(linkProfile)
		if (elems.length > 0) {
			this.#linkProfile = elems
		}
		// Apps uses a class because there is more than one link on the page
		elems = document.getElementsByClassName(linkAdmin)
		if (elems.length > 0) {
			this.#linkAdmin = elems
		}
		elems = document.getElementsByClassName(linkLogin)
		if (elems.length > 0) {
			this.#linkLogin = elems
		}
		elems = document.getElementsByClassName(linkLogout)
		if (elems.length > 0) {
			this.#linkLogout = elems
		}
		elems = document.getElementsByClassName(linkOrders)
		if (elems.length > 0) {
			this.#linkOrders = elems
		}
	}

	// showLinks is meant to be called once on page load.
	// Refresh the page on state change, e.g. session cookie set or cleared
	showLinks() {
		let loggedIn = false
		const session = Cookies.val("session")
		if (Cookies.isErr(session)) {
			// Session cookie doesn't exist
		} else {
			loggedIn = true
		}

		// Query params
		// https://stackoverflow.com/a/901144/639133
		const urlParams = new URLSearchParams(window.location.search);
		const redirect = urlParams.get("redirect");
		if (redirect) {
			// Assuming redirect param is only set when verifying email,
			// e.g. from the cart, as opposed to navigating to the login page
			if (this.#headerLoginVerifyEmail) {
				Array.from(this.#headerLoginVerifyEmail).forEach((elem) => {
					elem.classList.remove(isHidden)
				})
			}
		}

		if (loggedIn) {
			// Headers
			 if (!redirect) {
				if (this.#headerLoginSwitchProfile) {
					Array.from(this.#headerLoginSwitchProfile).forEach((elem) => {
						elem.classList.remove(isHidden)
					})
				}
			}

			// Links
			if (this.#linkProfile) {
				Array.from(this.#linkProfile).forEach((elem) => {
					elem.classList.remove(isHidden)
				})
			}
			if (this.#linkAdmin) {
				Array.from(this.#linkAdmin).forEach((elem) => {
					elem.classList.remove(isHidden)
				})
			}
			if (this.#linkLogout) {
				Array.from(this.#linkLogout).forEach((elem) => {
					elem.classList.remove(isHidden)
				})
			}
			if (this.#linkOrders) {
				Array.from(this.#linkOrders).forEach((elem) => {
					elem.classList.remove(isHidden)
				})
			}

		} else {
			// Headers
			if (!redirect) {
				if (this.#headerLogin) {
					Array.from(this.#headerLogin).forEach((elem) => {
						elem.classList.remove(isHidden)
					})
				}
			}

			// Links
			if (this.#linkLogin) {
				Array.from(this.#linkLogin).forEach((elem) => {
					elem.classList.remove(isHidden)
				})
			}
		}
	}
}
