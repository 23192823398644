import { sprintf } from "../sprintf/sprintf.esm.js"
import { Events } from "../events/events.ts"
import { htmxAfterRequest } from "../htmx/htmx.ts"

// Stubs class changes the stub server response using query params.
// This can be used to demo behaviour interactively in the browser.
// It's also useful for testing the front-end with stub data
export class Stubs {
	#initialised = false

	#hxHeaders() {
		const hxHeadersAttr = "hx-headers"
		const body = document.getElementsByTagName("body")[0]
		if (!body) {
			console.error("body tag not found")
			return
		}
		const hxHeaders = body.getAttribute(hxHeadersAttr)
		if (hxHeaders) {
			const hxHeadersObj = JSON.parse(hxHeaders)
			const params = new URLSearchParams(document.location.search)

			// x-status changes the HTTP response status,
			// and overrides the response body if a corresponding stub exists, 
			// e.g. ?x-status=400 might use the stub POST-400.html
			// Optionally a target may be specified, 
			// e.g. ?x-status=400&x-status-id=foo, 
			// this sets the hx-headers attr on an element with id="foo"
			const param = "x-status"
			const xStatus = params.get(param)
			if (xStatus) {
				const paramTargetID = "x-status-id"
				const targetID = params.get(paramTargetID)
				if (targetID) {
					// console.info("targetID", targetID)
					const target = document.getElementById(targetID)
					// console.info("target", target)
					if (target instanceof HTMLElement) {
						// Set hx-headers on matched element
						const val = sprintf(
							'{"%s": %d}', param, xStatus)
						// Overrides headers set on body
						target.setAttribute(hxHeadersAttr, val)
						// console.info(sprintf("%s set on", hxHeadersAttr), target)

					} else {
						// Selector must match one HTMLElement
						// console.error(sprintf(
						// 	"HTMLElement not matched for selector #%s", targetID), target)
						return
					}

				} else {
					// Default target is body tag
					hxHeadersObj[param] = xStatus
				}

				// Set hx-headers on body
				if (!hxHeadersObj) {
					console.error("JSON.parse", hxHeaders)
				}
				body.setAttribute(hxHeadersAttr, JSON.stringify(hxHeadersObj))
				console.info(sprintf("%s updated on body", hxHeadersAttr))
			}
		}
	}

	// setConfig from query params prefixed with "x-"
	#setConfig() {
		// TODO Make hxHeaders return target and val, or error.
		// TODO Rather set config on htmx.config? See app.ts
		this.#hxHeaders()
	}

	init() {
		if (this.#initialised) {
			console.error("already initialised")
			return
		}

		// For lazy loading with hx-trigger, use the appAfterInit event.
		// Instead of the htmxLoad event, it might fire before this method
		// https://htmx.org/attributes/hx-trigger/
		this.#setConfig()

		// Set config after htmx has added new elements to the DOM
		// https://htmx.org/events/#htmx:afterRequest
		Events.addListener(Stubs.name, htmxAfterRequest, () => {
			this.#setConfig()
		})

		this.#initialised = true
	}
}
