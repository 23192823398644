import { Alpine } from "./deps.ts";

import { getComboboxCallbacks } from "./combobox.ts"
import { getCustomRules } from "./validation.ts"

import { Tooltip } from './libs/alpine/tooltip.ts'
import { Utils } from "./libs/alpine/utils.ts"
import { Bulma } from "./libs/bulma/bulma.ts"
import { Combobox } from './libs/alpine/combobox.ts'
import { Cookies } from "./libs/cookies/cookies.ts"
import { appAfterInit } from "./libs/events/app.ts"
import { Events } from "./libs/events/events.ts"
import { ajax, HtmxConfig } from "./types/htmx.d.ts"
import { HtmxUtils } from "./libs/htmx/utils.ts"
import { sprintf } from "./libs/sprintf/sprintf.esm.js"
import { Stubs } from "./libs/stubs/stubs.ts"
import { Toggle } from "./toggle.ts"
import { Validation } from "./libs/validation/validation.ts"
import { Cart } from "./cart.ts"

// Define globals
// https://stackoverflow.com/a/62287021/639133
declare global {
	interface Window {
		// deno-lint-ignore no-explicit-any
		Alpine: any
		// deno-lint-ignore no-explicit-any
		app: any
		// deno-lint-ignore no-explicit-any
		cart: any
		// htmx is imported with a script tag
		htmx: {
			config: HtmxConfig,
			ajax: typeof ajax
		}
		// deno-lint-ignore no-explicit-any
		sprintf: any
	}
}

// Use a closure to make lib instances private
// https://www.mozey.co/post/javascript-modules/
const app = (function() {
	// Create new lib instances.
	// The private initialised property ensures init is only called once
	const bulma = new Bulma()
	const cart = new Cart()
	const combobox = new Combobox({
		Callbacks: getComboboxCallbacks()
	})
	const cookies = new Cookies()
	const htmxUtils = new HtmxUtils()
	// TODO Stubs lib if for dev only?
	const stubs = new Stubs()
	const toggle = new Toggle()
	const tooltip = new Tooltip()
	const utils = new Utils()
	const validation = new Validation({
		CustomRules: getCustomRules(),
	})

	// "TypeScript's `module` and `namespace` are discouraged
	// Use ES2015 module syntax (`import`/`export`) to organize
	// the code instead deno-lint(no-namespace)"
	// https://stackoverflow.com/a/58271234/639133
	return {
		// Cookies is uppercase because it's the class, not an instance
		Cookies: Cookies,
		Events: Events,

		cart: cart,

		utils: utils,

		validation: validation,

		logout: () => {
			Cookies.clear("session")
			// Cookies.clear("auth") // This doesn't work...
			// TODO Make request to server to delete session?
			// The server then responds with Set-Cookie headers to clear cookies?
			document.location = "/"
		},

		start: () => {
			globalThis.htmx.config.defaultFocusScroll = true
			// console.info("htmx.config", globalThis.htmx.config)

			// init event listeners etc, order of initialisation doesn't matter.
			// Register alpine extensions "IN BETWEEN when the Alpine global object
			// is imported and when Alpine is initialized with Alpine.start"
			bulma.init()
			cart.init()
			combobox.init()
			cookies.init()
			htmxUtils.init()
			stubs.init()
			tooltip.init()
			utils.init()
			validation.init()

			// alpine is started after registering event listeners and extensions
			Alpine.start()

			// TODO Check user role stored in session cookie
			// https://github.com/shopd/shopd-issues/issues/33
			toggle.showLinks()

			// The appAfterInit event can be used for hydration with htmx.
			// The load event can't be used, it might fire before this code runs
			// https://htmx.org/events/#htmx:load
			// Assuming htmx initialized itself before DOMContentLoaded
			const els =
				document.body.querySelectorAll(
					sprintf('[hx-trigger="%s"]', appAfterInit))
			els.forEach((el) => {
				Events.dispatchOnElement("app", el, appAfterInit)
			})
		},
	}
})

// Set deps and app on window for better DX,
// they can then be accessed from the Developer Tools console
globalThis.Alpine = Alpine
globalThis.sprintf = sprintf

// start must be called on DOMContentLoaded
globalThis.app = app()
