// TODO These can't be added to type definition file? types/htmx-custom.d.ts

export const htmxAfterRequest = "htmx:afterRequest"

export const htmxAfterSwap = "htmx:afterSwap"

export const htmxBeforeSwap = "htmx:beforeSwap"

export const htmxLoad = "htmx:load"

export const htmxResponseError = "htmx:responseError"
