import { CallbackMap, CallbackName, ComboboxCB } from "./libs/alpine/combobox.ts"

export function getComboboxCallbacks(): CallbackMap {
	// TODO Make lookups on cbMap always use lowercase
	const cbMap: CallbackMap = new Map<CallbackName, ComboboxCB>

	// tags
	cbMap.set("tags", (data: Map<string, string>) => {
		const el = document.getElementById("Tags") as HTMLTextAreaElement
		const tags = el.value.split(" ")
		const value = data.get("value")
		if (value) {
			if (!tags.includes(value)) {
				tags.push(value)
				el.value = tags.join(" ").trim()
			}
		}
	})

	// users
	cbMap.set("users", (data: Map<string, string>) => {
		const id = document.getElementById("UserID") as HTMLInputElement
		const value = data.get("value")
		if (value) {
			id.value = value
		}

		const displayEl = 
			document.getElementById("UserDisplay") as HTMLInputElement
		const display = data.get("display")
		if (display) {
			displayEl.value = display
		}
	})

	return cbMap
}
