import { Alpine } from "./deps.ts"
import { isHidden } from "./toggle.ts"
import { Events } from "./libs/events/events.ts"
import { click } from "./libs/events/standard.ts"
import { alpineInit } from "./libs/events/alpine.ts"
import { sprintf } from "./libs/sprintf/sprintf.esm.js"

// Makes use of localStorage
// https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage
// Storage wrapper is not required, unlike cookie,
// the localStorage API is nice to use?

// cartAdd event
const cartAdd = "cart:add"

// cartAddEvent custom data
interface cartAddEvent extends Event {
	detail: {
		inputID: string
	}
}

// cartClear event
const cartClear = "cart:clear"

// cartClearEvent custom data
interface cartClearEvent extends Event {
	detail: {
		orderNo: string
	}
}

export type Sku = string

// Calculations are done server side,
// therefore price is not a cart property
interface LineItem {
	Qty: number
}

export type LineItems = Map<Sku, LineItem>

const linkCart = "app-link-cart"

export class Cart {
	#initialised = false

	#cartKey = "cart"

	#lineItems: LineItems

	#linkCart?: HTMLCollectionOf<Element>

	constructor() {
		const elems = document.getElementsByClassName(linkCart)
		if (elems.length > 0) {
			this.#linkCart = elems
		}
		this.#lineItems = new Map<Sku, LineItem>()
		this.load()
	}

	clear() {
		this.#lineItems = new Map<Sku, LineItem>()
		this.save()
	}

	add(sku: Sku, qty: number) {
		if (sku.trim() == "") {
			console.error("invalid sku")
			return
		}
		if (qty == 0) {
			console.error("nothing to add")
			return
		}

		let newQty = qty
		const item = this.#lineItems.get(sku)
		if (item) {
			// Increment existing line line
			newQty = item.Qty + qty
		}
		this.#lineItems.set(sku, {
			Qty: newQty
		})
	}

	load() {
		const cart = localStorage.getItem(this.#cartKey);
		if (cart) {
			const data = JSON.parse(cart)
			if (data && data.Lines) {
				// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/entries
				for (const [key, value] of Object.entries(data.Lines)) {
					this.#lineItems.set(key, value as LineItem)
				}
			}
		}
	}

	// deno-lint-ignore no-explicit-any
	object(): any {
		return { Lines: Object.fromEntries(this.#lineItems) }
	}

	json(): string {
		// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/fromEntries
		return JSON.stringify(this.object())
	}

	// TODO Show checkout link if cart not empty
	save() {
		localStorage.setItem(this.#cartKey, this.json())
	}

	empty(): boolean {
		return this.#lineItems.size == 0
	}

	// toggleLinks to show or hide after updating the cart
	toggleLinks() {
		const href = location.href
		if (href.includes("admin") ||
			href.includes("cart") ||
			href.includes("checkout")) {
			return
		}
		const empty = this.empty()
		if (this.#linkCart) {
			Array.from(this.#linkCart).forEach((elem) => {
				if (empty) {
					elem.classList.add(isHidden)
				} else {
					elem.classList.remove(isHidden)
				}
			})
		}
	}

	// register Alpine.js directives etc
	init() {
		if (this.#initialised) {
			console.error("already initialised")
			return
		}

		Events.addListener(Cart.name, cartClear, (e: Event) => {
			const event = e as cartClearEvent
			if (!event.detail) {
				console.error("invalid detail", event)
				return
			}
			// TODO Set orderNo on localStorage before clearing?
			// if (event.detail.orderNo) {
			// 	...
			// }
			let msg = "Clearing cart"
			if (event.detail.orderNo.trim() != "") {
				msg = sprintf(
					"Clearing cart, reserved order %s", event.detail.orderNo)
			}
			console.info(msg)
			this.clear()
		})

		Events.addListener(Cart.name, alpineInit, () => {

			Alpine.directive("cart", (el: Node) => {
				// Bind one event listener for all the add buttons
				Events.addListenerToElement(Cart.name,
					(el as Element), cartAdd, (e: Event) => {
						const event = e as cartAddEvent
						const input =
							document.getElementById(event.detail.inputID) as HTMLInputElement
						if (input) {
							const value = parseInt(input.value)
							if (isNaN(value)) {
								console.error(sprintf("value is not a number %s", input.value))
							}
							if (value >= 1) {
								// Use the cart page to remove items
								this.add(input.name, value)
								this.save()
								this.toggleLinks()
							}
						}
					}
				)
			})

			// deno-lint-ignore no-explicit-any
			Alpine.directive("cart-add", (el: Node, o: any) => {
				Events.addListenerToElement(Cart.name,
					(el as Element), click, () => {
						Events.dispatchOnElement(
							Cart.name, el as HTMLElement, cartAdd, {
							inputID: o.expression
						})
					}
				)
			})
		})

		this.toggleLinks()
		this.#initialised = true
	}
}
