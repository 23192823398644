// Standard events used by this app are listed here
// https://developer.mozilla.org/en-US/docs/Web/Events

export const change = "change"

export const click = "click"

export const keyup = "keyup"

export const submit = "submit"
