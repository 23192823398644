import { Alpine } from "../../deps.ts"
import { Events } from "../events/events.ts"
import { alpineInit } from "../events/alpine.ts"


declare global {
	interface Window {
		// deno-lint-ignore no-explicit-any
		tippy: any
  }
}

export class Tooltip {
	#initialised = false

	constructor() {
		// Nothing to do there?
	}

	init() {
		if (this.#initialised) {
			console.error("already initialised")
			return
		}

		Events.addListener(Tooltip.name, alpineInit, () => {
			// https://alpinejs.dev/component/tooltip

			// Magic: $tooltip
			Alpine.magic('tooltip', (el: Node) => (message: string) => {
				// TODO Which type to use here instead of any?
				// deno-lint-ignore no-explicit-any
				const instances: any = globalThis.tippy([el as Element], {
					content: message, trigger: 'manual'
				})
				const instance = instances[0]

				instance.show()
				setTimeout(() => {
					instance.hide()
					setTimeout(() => instance.destroy(), 150)
				}, 2000)
			})

			// Directive: x-tooltip
			Alpine.directive('tooltip', (el: Node, { expression }) => {
				globalThis.tippy([el as Element], { content: expression })
			})

		})

		this.#initialised = true
	}

}
