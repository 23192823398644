
// EventHandler
export interface EventHandler {
	(e: Event): void
}

// Events class to bind listeners and dispatch events.
// Always use this class to make testing and debugging easier
export class Events {
	static #debug: boolean

	static debug(debug: boolean) {
		Events.#debug = debug
	}

	// addListener on global scope
	static addListener(caller: string,
		eventName: string, handler: EventHandler): void {

		addEventListener(eventName, (event: Event) => {
			if (Events.#debug) {
				console.info(caller, eventName, event)
			}
			handler(event)
		})
	}

	// addListenerToElement
	static addListenerToElement(caller: string,
		el: Element, eventName: string, handler: EventHandler): void {

		el.addEventListener(eventName, (event: Event) => {
			if (Events.#debug) {
				console.info(caller, eventName, event)
			}
			handler(event)
		})
	}

	// https://developer.mozilla.org/en-US/docs/Web/Events/Creating_and_triggering_events
	// deno-lint-ignore no-explicit-any
	static dispatch(caller: string, eventName: string, detail?: any): void {
		if (Events.#debug) {
			console.info(caller, eventName)
		}
		dispatchEvent(new CustomEvent(eventName, { detail: detail }))
	}

	static dispatchOnElement(caller: string,
		// deno-lint-ignore no-explicit-any
		el: Element, eventName: string, detail?: any): void {

		const event = new CustomEvent(eventName, {
			bubbles: true,
			cancelable: true,
			detail: detail
		})
		if (Events.#debug) {
			console.info(caller, el, eventName)
		}
		el.dispatchEvent(event)
	}
}
