/**
 * Code below mostly copied from Bulma docs, i.e.
 * "The Bulma package does not come with any JavaScript.
 * Here is however an implementation example..."
 * https://bulma.io/documentation/components/navbar
 */
import { Events } from "../events/events.ts"
import { click, change } from "../events/standard.ts"
import { HtmxResponseEvent } from "../../types/htmx-custom.d.ts"
import { htmxAfterRequest } from "../htmx/htmx.ts"
import { sprintf } from "../sprintf/sprintf.esm.js"

// Do not change the display property on the element style
// https://developer.mozilla.org/en-US/docs/Web/CSS/display
// Instead, toggle the Bulma Response Helpers
// https://bulma.io/documentation/helpers/visibility-helpers/#hide
export const isHidden = "is-hidden"

export class Bulma {
	#initialised = false

	#navbar() {
		// Get all "navbar-burger" elements
		const navbarBurgers = Array.prototype.slice.call(
			document.querySelectorAll('.navbar-burger'), 0)

		// Add a click event on each of them
		navbarBurgers.forEach(el => {
			Events.addListenerToElement(Bulma.name, el, click, () => {

				// Get the target from the "data-target" attribute

				const target =
					document.getElementById(el.dataset.target) as HTMLDivElement

				// Toggle the "is-active" class on both
				// the "navbar-burger" and the "navbar-menu"
				el.classList.toggle('is-active')
				target.classList.toggle('is-active')

			})
		})
	}

	// TODO Not part of Bulma but makes sense to keep this in here?
	// Floating back-to-top button
	// https://www.w3schools.com/howto/howto_js_scroll_to_top.asp
	#backToTop() {
		// Get the button:
		const backToTop = document.getElementById("app-back-to-top")
		if (backToTop == null) {
			return
		}

		// When the user scrolls down 20px from the top of the document, show the button
		globalThis.onscroll = function () { scrollFunction(backToTop as HTMLElement) }

		function scrollFunction(backToTop: HTMLElement) {
			if (document.body.scrollTop > 20 ||
				document.documentElement.scrollTop > 20) {
				// backToTop.style.display = "block"
				backToTop.classList.remove(isHidden)
			} else {
				// backToTop.style.display = "none"
				backToTop.classList.add(isHidden)
			}
		}

		// When the user clicks on the button, scroll to the top of the document
		backToTop.onclick = function () {
			// For Safari
			document.body.scrollTop = 0
			// For Chrome, Firefox, IE and Opera
			document.documentElement.scrollTop = 0
		}
	}

	#fileUploadMap: Map<string, boolean> = new Map<string, boolean>()

	// File upload input requires JavaScript to retrieve the selected file name
	// https://bulma.io/documentation/form/file/#javascript
	#fileUpload() {
		const element = document.querySelector("input.file-input")
		if (element) {
			const input = element as HTMLInputElement
			// Only add listeners once
			const initialised = this.#fileUploadMap.get(input.id)
			if (initialised == undefined) {
				Events.addListenerToElement(Bulma.name, input, change, () => {
					if (input.files && input.files.length > 0) {
						// "general sibling combinator...
						// matches all iterations of the second element,
						// that are following the first element (not necessarily immediately),
						// and are children of the same parent
						// https://developer.mozilla.org/en-US/docs/Web/CSS/General_sibling_combinator
						const fileName =
							document.querySelector(sprintf("#%s ~ .file-name", input.id))
						if (fileName) {
							fileName.textContent = input.files[0].name
						}
					}
				})
				this.#fileUploadMap.set(input.id, true)
			}
		}
	}

	// TODO Param to specify which components to init?
	init() {
		if (this.#initialised) {
			console.error("already initialised")
			return
		}

		// Don't use a configurable CSS prefix,
		// makes it harder to search the code for a specific string.
		// Use "app-" prefix for classes that define custom styles and behaviour

		this.#navbar()
		this.#backToTop()
		this.#fileUpload()

		// Footer jumps up after page content is swapped with htmx,
		// adding styles to prevent this seems complicated or impossible?
		// Just hide the footer
		const footerHidden = false
		Events.addListener(Bulma.name, htmxAfterRequest, (e: Event) => {
			if (!footerHidden) {
				// Hide the footer
				document.getElementById("footer")?.classList.add("is-hidden")
			}

			// Search form has a list of results
			const detail = (e as HtmxResponseEvent).detail
			// console.info("detail.target", detail.target)
			if (detail.target.querySelector(".app-search-results")) {
				const section = detail.target.closest(".app-search")
				if (section) {
					// This class can be used for styling
					section.classList.add("app-has-list")
				}
			}

			// Forms may be lazy loaded
			// TODO Use UIkit for this instead?
			// https://github.com/shopd/shopd-issues/issues/67
			// https://getuikit.com/docs/upload
			this.#fileUpload()
		})

		this.#initialised = true
	}
}
