import { Alpine } from "../../deps.ts"
import { Events } from "../events/events.ts"
import { alpineInit } from "../events/alpine.ts"
import { sprintf } from "../sprintf/sprintf.esm.js"
import { click, keyup } from "../events/standard.ts"
import { htmxAfterSwap } from "../htmx/htmx.ts"
import { HtmxResponseEvent } from "../../types/htmx-custom.d.ts"

type ComboID = string

// ComboboxCB is a function that accepts a string value
export interface ComboboxCB {
	(data: Map<string, string>): void
}

export type CallbackName = string

export type CallbackMap = Map<CallbackName, ComboboxCB>

interface ComboItem {
	// id of the combobox input element,
	// the menu element must have the same prefix
	id: ComboID
	// url to search for completion options
	url: string
	// wrapper is the directive wrapper element
	wrapper: HTMLElement
	// input is the input element
	input: HTMLInputElement
	// searchBtn for menu
	searchBtn: HTMLElement
	// closeBtn for menu
	closeBtn: HTMLElement
	// menu is the menu element
	menu: HTMLElement
	// error element for displaying errors
	error: HTMLElement
	// timeout is used to debounce events on a combobox
	timeout: null | number
	// callback function on value selected. 
	// If specified, the combobox input won't be updated
	callbackName: string
}

type ComboMap = Map<ComboID, ComboItem>

// DebounceCB is the debounce callback function
export interface DebounceCB {
	(): void
}

export class ComboErr extends Error {
	// deno-lint-ignore no-explicit-any
	constructor(...params: any) {
		super(...params)
	}

	string(): string {
		return this.message
	}
}

export interface ComboboxOptions {
	Callbacks?: CallbackMap
}

export class Combobox {
	#initialised = false

	#combos: ComboMap

	#callbacks: CallbackMap

	#debounceWaitMS: number

	#debug: boolean

	#minLength: number

	#querySearch: string

	constructor(options?: ComboboxOptions) {
		this.#combos = new Map<ComboID, ComboItem>()

		this.#callbacks = new Map<CallbackName, ComboboxCB>

		if (options) {
			if (options.Callbacks) {
				this.#callbacks = options.Callbacks
			}
		}

		// TODO More constructor options
		this.#debounceWaitMS = 900
		this.#debug = false
		this.#minLength = 2
		this.#querySearch = "search"
	}

	// deno-lint-ignore no-explicit-any
	static isErr(err: any): boolean {
		if (err instanceof ComboErr) {
			return true
		}
		return false
	}

	// getCombo, the directive wrapper, not the input element
	#getCombo(id: ComboID): ComboItem | ComboErr {
		const combo = this.#combos.get(id)
		if (combo == undefined) {
			return new ComboErr(sprintf("combobox %s is not registered", id))
		}
		return combo
	}

	// registerCombo if it contains all the required parts
	#registerCombo(wrapper: HTMLElement, id: ComboID, url: string): void | ComboErr {
		const input = wrapper.querySelector(
			sprintf("#%s", id)) as HTMLInputElement
		if (!input) {
			return new ComboErr(sprintf("input not found for combobox ", id))
		}
		const searchBtn = wrapper.querySelector(
			sprintf("#%s-search", id)) as HTMLElement
		if (!searchBtn) {
			return new ComboErr(sprintf("search button not found for combobox ", id))
		}
		const closeBtn = wrapper.querySelector(
			sprintf("#%s-close", id)) as HTMLElement
		if (!closeBtn) {
			return new ComboErr(sprintf("close button not found for combobox ", id))
		}
		const menu = wrapper.querySelector(
			sprintf("#%s-menu", id)) as HTMLElement
		if (!menu) {
			return new ComboErr(sprintf("menu not found for combobox ", id))
		}
		const error = wrapper.querySelector("[x-combobox-error]") as HTMLElement
		if (!error) {
			return new ComboErr(sprintf("error element not found for combobox ", id))
		}

		const combo = <ComboItem>{
			id: id,
			url: url,
			wrapper: wrapper,
			input: input,
			searchBtn: searchBtn,
			closeBtn: closeBtn,
			menu: menu,
			error: error,
		}

		// Callback?
		const callbackName = wrapper.getAttribute("x-combobox-cb")
		if (callbackName) {
			if (callbackName.trim() == "") {
				console.error("invalid x-combobox-cb")
			} else {
				combo.callbackName = callbackName
			}
		}

		// Register combo
		this.#combos.set(id, combo)

		// Add listeners to wrapper
		this.#addListeners(wrapper, id)

		// Search listener
		Events.addListenerToElement(
			Combobox.name, searchBtn, click, (event: Event) => {
				if (this.#debug) {
					console.info("search button click", event)
				}
				this.#search(id)
				input.focus()
			})
		// Close listener
		Events.addListenerToElement(
			Combobox.name, closeBtn, click, (event: Event) => {
				if (this.#debug) {
					console.info("close button click", event)
				}
				this.#hide(id)
				input.focus()
			})

		// Hide close button and show search
		this.#hide(id)

		return
	}

	#addListeners(wrapper: HTMLElement, id: ComboID) {
		// Keyup event
		// https://developer.mozilla.org/en-US/docs/Web/API/Element/keyup_event
		Events.addListenerToElement(Combobox.name,
			wrapper, keyup, (event: Event) => {
				const kbe = (event as KeyboardEvent)
				// See keyup_event link above: "Firefox bug 354358..."
				if (kbe.isComposing || (kbe.keyCode && kbe.keyCode === 229)) {
					return
				}
				// "key property [take] into consideration the state of modifier keys 
				// such as Shift as well as the keyboard locale and layout"
				// https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key
				const key = kbe.key
				if (key == "Escape") {
					// Escape closes the menu
					this.#hide(id)
					const combo = this.#getCombo(id)
					if (combo instanceof ComboErr) {
						console.error(combo.string())
						return
					}
					combo.input.focus()
					return
				}

				// Check event target
				const input = (event.target as HTMLElement)
				if (input.tagName.toLowerCase() != "input") {
					// Keypress was not on the input
					return
				}

				switch (key) {
					// Keys to ignore
					case "Alt":
					case "ArrowDown":
					case "ArrowLeft":
					case "ArrowRight":
					case "ArrowUp":
					case "Control":
					case "Enter":
					case "Escape":
					case "Meta":
					case "Shift":
					case "Tab":
						return
				}
				if (kbe.target != null) {
					const el = (kbe.target as HTMLElement)
					if (el.tagName.toLowerCase() == "button") {
						// Prevent missing id error when key is pressed on a button
						// e.g. pressing space-bar on the submit button
						return
					}

					// Search for completions after a delay
					const err = this.debounce(id, () => {
						if (this.#debug) {
							console.info("keyup", wrapper)
						}
						this.#search(id)
					}, this.#debounceWaitMS)
					if (err instanceof ComboErr) {
						console.error(err.string())
						return
					}
				}
			})
	}

	#dataMap(el: HTMLAnchorElement): Map<string, string> {
		const data = new Map<string, string>()
		const attrs = el.getAttributeNames()
		for (const attr of attrs) {
			if (attr.startsWith("data-")) {
				const value = el.getAttribute(attr)
				if (value) {
					data.set(attr.replace("data-", ""), value)
				}
			}
		}
		return data
	}

	#addMenuListeners(menu: HTMLElement, id: ComboID) {
		Events.addListenerToElement(Combobox.name,
			menu, click, (event: Event) => {
				if (this.#debug) {
					console.info("menu click", event)
				}
				const link = event.target as HTMLAnchorElement
				const combo = this.#getCombo(id)
				if (combo instanceof ComboErr) {
					console.error(combo.string())
					return
				}
				const data = this.#dataMap(link)
				const value = data.get("value")
				if (value) {
					if (combo.callbackName) {
						const cb = this.#callbacks.get(combo.callbackName)
						if (cb == undefined) {
							console.error(
								sprintf("callback is undefined %s ", combo.callbackName))
						} else {
							// Callback
							cb(data)
							// TODO Use return value from cb to toggle clearing input?
							combo.input.value = ""
						}
					} else {
						// Update combo input
						combo.input.value = value
					}
				} else {
					console.error("invalid value for option", link)
				}
				this.#hide(id)
				combo.input.focus()
			})
	}

	#show(id: ComboID): void | ComboErr {
		const combo = this.#getCombo(id)
		if (combo instanceof ComboErr) {
			return combo
		}

		// Only show completions if input still has focus,
		// if the user tabbed away then don't show 
		if (document.activeElement) {
			if (combo.input.id != document.activeElement.id) {
				// Combo does not have focus
				return
			}
		} else {
			// Document does not have focus
			return
		}

		// Show
		combo.searchBtn.classList.add("is-hidden")
		combo.closeBtn.classList.remove("is-hidden")
		combo.menu.classList.remove("is-hidden")

		return
	}

	#hide(id: ComboID): ComboItem | ComboErr {
		const combo = this.#getCombo(id)
		if (combo instanceof ComboErr) {
			return combo
		}
		combo.searchBtn.classList.remove("is-hidden")
		combo.closeBtn.classList.add("is-hidden")
		combo.menu.classList.add("is-hidden")
		return combo
	}

	#reset(id: ComboID): void | ComboErr {
		const combo = this.#hide(id)
		if (combo instanceof ComboErr) {
			return combo
		}
		combo.input.value = ""
	}

	// search makes a request to the server with the given query string,
	// and updates the list of completion options with the results
	#search(id: ComboID): void | ComboErr {
		const combo = this.#getCombo(id)
		if (combo instanceof ComboErr) {
			return combo
		}

		// Check min length for input value
		const q = combo.input.value
		if (q.length < this.#minLength) {
			// This is not a validation rule, it's the min length for search
			combo.error.innerHTML = sprintf(
				"Type at least %s characters to search", this.#minLength)
			combo.error.classList.remove("is-hidden")
			// Read input value after debounce
			this.#hide(id)
			return
		} else {
			combo.error.classList.add("is-hidden")
		}

		// Note the caveats when parsing URLs
		// https://stackoverflow.com/a/69335880/639133
		// https://developer.mozilla.org/en-US/docs/Web/API/URL
		const u = new URL(combo.url, "http://example.com") // base is not used
		const params = new URLSearchParams(u.search)
		params.set(this.#querySearch, q)
		const url = sprintf("%s?%s", u.pathname, params.toString())
		// https://htmx.org/api/#ajax
		// console.info("htmx.config", window.htmx.config)
		window.htmx.ajax("GET", url, combo.menu).then(
			() => {
				// Add listeners to menu
				this.#addMenuListeners(combo.menu, id)

				this.#show(id)

				// Do not focus on first link here,
				// user might want to keep typing. 
				// It's easy enough to just tab focus to the menu
			})
	}

	// debounce events by combobox ID.
	// This is why it's required https://alpinejs.dev/directives/on#debounce
	// Debounce logic inspired by node_modules/alpinejs/src/utils/debounce.js
	debounce(id: ComboID, cb: DebounceCB, wait: number):
		void | ComboErr {

		// Get combo
		const combo = this.#combos.get(id)
		if (combo == undefined) {
			return new ComboErr(sprintf("combobox %s is not registered", id))
		}

		if (combo.timeout) {
			// Clear current timeout
			clearTimeout(combo.timeout)
		}

		// Register new timeout
		const later = () => {
			cb.apply(this, [])
		}
		combo.timeout = setTimeout(later, wait)
		return
	}

	init() {
		if (this.#initialised) {
			console.error("already initialised")
			return
		}

		Events.addListener(Combobox.name, alpineInit, () => {
			// Directive: x-combobox
			Alpine.directive('combobox', (el: Node, { expression }) => {
				const wrapper = el as HTMLElement
				const id = wrapper.getAttribute("x-combobox-id")
				if (!id || id.trim() == "") {
					console.error("invalid x-combobox-id")
					return
				}
				const url = expression as string
				const err = this.#registerCombo(wrapper, id, url)
				if (err instanceof ComboErr) {
					console.error(err.string())
				}
			})

			// x-combobox-reset is not a directive,
			// but it's used with the htmxAfterSwap event to reset combos.
			// Note that the combo is not reset on error response.
			// Use this attribute to list comma separated combo IDs on the htmx target
			Events.addListener(
				Combobox.name, htmxAfterSwap, (e: Event) => {
					const event = e as HtmxResponseEvent
					if (!event.detail) {
						console.error("invalid detail", event)
						return
					}
					if (!event.detail.target) {
						console.error("invalid detail.target", event.detail)
						return
					}
					// detail.target - the target of the request
					// https://htmx.org/events/#htmx:afterSwap
					const target = event.detail.target as HTMLElement
					const value = target.getAttribute("x-combobox-reset")
					if (value) {
						const ids = value.split(",")
						ids.forEach((id) => {
							const err = this.#reset(id)
							if (err instanceof ComboErr) {
								console.error(err.string())
							}
						})
					}
				})
		})

		this.#initialised = true
	}

}
